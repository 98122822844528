// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-better-together-tsx": () => import("./../../../src/pages/better-together.tsx" /* webpackChunkName: "component---src-pages-better-together-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-dmca-tsx": () => import("./../../../src/pages/dmca.tsx" /* webpackChunkName: "component---src-pages-dmca-tsx" */),
  "component---src-pages-ecards-tsx": () => import("./../../../src/pages/ecards.tsx" /* webpackChunkName: "component---src-pages-ecards-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../../../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-illumeforslack-index-tsx": () => import("./../../../src/pages/illumeforslack/index.tsx" /* webpackChunkName: "component---src-pages-illumeforslack-index-tsx" */),
  "component---src-pages-illumeforslack-onboarding-tsx": () => import("./../../../src/pages/illumeforslack/onboarding.tsx" /* webpackChunkName: "component---src-pages-illumeforslack-onboarding-tsx" */),
  "component---src-pages-illumeforslack-pricing-tsx": () => import("./../../../src/pages/illumeforslack/pricing.tsx" /* webpackChunkName: "component---src-pages-illumeforslack-pricing-tsx" */),
  "component---src-pages-index-old-tsx": () => import("./../../../src/pages/indexOld.tsx" /* webpackChunkName: "component---src-pages-index-old-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-marketing-tsx": () => import("./../../../src/pages/marketing.tsx" /* webpackChunkName: "component---src-pages-marketing-tsx" */),
  "component---src-pages-occasions-anniversary-tsx": () => import("./../../../src/pages/occasions/anniversary.tsx" /* webpackChunkName: "component---src-pages-occasions-anniversary-tsx" */),
  "component---src-pages-occasions-baby-shower-tsx": () => import("./../../../src/pages/occasions/baby-shower.tsx" /* webpackChunkName: "component---src-pages-occasions-baby-shower-tsx" */),
  "component---src-pages-occasions-birthday-best-friend-tsx": () => import("./../../../src/pages/occasions/birthday/best-friend.tsx" /* webpackChunkName: "component---src-pages-occasions-birthday-best-friend-tsx" */),
  "component---src-pages-occasions-birthday-boyfriend-tsx": () => import("./../../../src/pages/occasions/birthday/boyfriend.tsx" /* webpackChunkName: "component---src-pages-occasions-birthday-boyfriend-tsx" */),
  "component---src-pages-occasions-birthday-brother-tsx": () => import("./../../../src/pages/occasions/birthday/brother.tsx" /* webpackChunkName: "component---src-pages-occasions-birthday-brother-tsx" */),
  "component---src-pages-occasions-birthday-colleague-tsx": () => import("./../../../src/pages/occasions/birthday/colleague.tsx" /* webpackChunkName: "component---src-pages-occasions-birthday-colleague-tsx" */),
  "component---src-pages-occasions-birthday-dad-tsx": () => import("./../../../src/pages/occasions/birthday/dad.tsx" /* webpackChunkName: "component---src-pages-occasions-birthday-dad-tsx" */),
  "component---src-pages-occasions-birthday-friend-tsx": () => import("./../../../src/pages/occasions/birthday/friend.tsx" /* webpackChunkName: "component---src-pages-occasions-birthday-friend-tsx" */),
  "component---src-pages-occasions-birthday-girlfriend-tsx": () => import("./../../../src/pages/occasions/birthday/girlfriend.tsx" /* webpackChunkName: "component---src-pages-occasions-birthday-girlfriend-tsx" */),
  "component---src-pages-occasions-birthday-mom-tsx": () => import("./../../../src/pages/occasions/birthday/mom.tsx" /* webpackChunkName: "component---src-pages-occasions-birthday-mom-tsx" */),
  "component---src-pages-occasions-birthday-relative-tsx": () => import("./../../../src/pages/occasions/birthday/relative.tsx" /* webpackChunkName: "component---src-pages-occasions-birthday-relative-tsx" */),
  "component---src-pages-occasions-birthday-sister-tsx": () => import("./../../../src/pages/occasions/birthday/sister.tsx" /* webpackChunkName: "component---src-pages-occasions-birthday-sister-tsx" */),
  "component---src-pages-occasions-farewell-tsx": () => import("./../../../src/pages/occasions/farewell.tsx" /* webpackChunkName: "component---src-pages-occasions-farewell-tsx" */),
  "component---src-pages-occasions-fathers-day-tsx": () => import("./../../../src/pages/occasions/fathers-day.tsx" /* webpackChunkName: "component---src-pages-occasions-fathers-day-tsx" */),
  "component---src-pages-occasions-get-well-soon-tsx": () => import("./../../../src/pages/occasions/get-well-soon.tsx" /* webpackChunkName: "component---src-pages-occasions-get-well-soon-tsx" */),
  "component---src-pages-occasions-mothers-day-tsx": () => import("./../../../src/pages/occasions/mothers-day.tsx" /* webpackChunkName: "component---src-pages-occasions-mothers-day-tsx" */),
  "component---src-pages-occasions-retirement-tsx": () => import("./../../../src/pages/occasions/retirement.tsx" /* webpackChunkName: "component---src-pages-occasions-retirement-tsx" */),
  "component---src-pages-occasions-teachers-tsx": () => import("./../../../src/pages/occasions/teachers.tsx" /* webpackChunkName: "component---src-pages-occasions-teachers-tsx" */),
  "component---src-pages-occasions-thank-you-tsx": () => import("./../../../src/pages/occasions/thank-you.tsx" /* webpackChunkName: "component---src-pages-occasions-thank-you-tsx" */),
  "component---src-pages-occasions-the-graduate-tsx": () => import("./../../../src/pages/occasions/the-graduate.tsx" /* webpackChunkName: "component---src-pages-occasions-the-graduate-tsx" */),
  "component---src-pages-occasions-tsx": () => import("./../../../src/pages/occasions.tsx" /* webpackChunkName: "component---src-pages-occasions-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-splitanygifts-tsx": () => import("./../../../src/pages/splitanygifts.tsx" /* webpackChunkName: "component---src-pages-splitanygifts-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-team-pricing-tsx": () => import("./../../../src/pages/team-pricing.tsx" /* webpackChunkName: "component---src-pages-team-pricing-tsx" */),
  "component---src-pages-teams-tsx": () => import("./../../../src/pages/teams.tsx" /* webpackChunkName: "component---src-pages-teams-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

