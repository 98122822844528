module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-55V2QDD","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"Q4sqa6AqeRW9Jw11rbK0N4cobiC9ORgj","devKey":"twYIVPj1tK6HWQgIHjBiruHBfzzPhOJ1","trackPage":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"illume | better together","short_name":"illume","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/images/favicon/favicon-96x96.png","icons":[{"src":"/favicon/android-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/favicon/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicon/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicon/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicon/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicon/android-icon-192x192.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"de3f3a01e6d31db63c03542163374f5b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/404","/privacy","/terms","*","/blog/*","/marketing"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://c0ff4792aeaf491db44b36d49813dff9@o444837.ingest.sentry.io/5839153"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-social9-socialshare/gatsby-browser.js'),
      options: {"plugins":[],"content":"5e50601b1de84392b31676dbc0b60fe4","async":true,"defer":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
